<template>
  <section class="bg-light h-100 p-3">
    <div>
      <h2>Nouveau mail</h2>
      <hr />
      <form
        class="row g-3 mb-4"
        @submit.prevent="sendMail"
        formnovalidate="formnovalidate"
      >
        <div class="col-12">
          <label for="inputMail1" class="form-label">Objet</label>
          <input
            type="text"
            class="form-control"
            v-model="form.object"
            :class="{ 'is-invalid': submitted && $v.form.email_faq.$error }"
          />
          <!-- <div
            v-if="submitted && $v.form.email_faq.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.email_faq.required"
              >Veuillez insérer votre adresse mail</span
            >
            <span v-if="!$v.form.email_faq.email"
              >Veuillez insérer une adresse mail valide</span
            >
          </div> -->
        </div>
        <div
          v-for="(input, index) in files"
          :key="`${index}`"
          class="col-12"
          :class="index != 0 && 'gy-2'"
        >
          <div class="row">
            <div class="col-5">
              <label for="inputNomAD" class="form-label">Nom document</label>
              <input
                type="text"
                class="form-control"
                required
                v-model="files[index].name"
              />
            </div>
            <div class="col-5">
              <label for="inputAD" class="form-label"
                >Téléverser le fichier</label
              >
              <input
                type="file"
                class="form-control"
                required
                @change="(e) => changeFile(e, index)"
                accept="application/pdf, image/*"
              />
              <div id="inputAD" class="form-text">En format .pdf</div>
            </div>
            <div
              class="col-1 gap-3 d-flex align-items-center justify-content-between"
            >
              <div
                @click="addField()"
                class="btn btn-primary shadow rounded-circle p-2"
              >
                <IcoAdd />
              </div>
              <div
                @click="removeField(index)"
                v-show="files.length > 1"
                class="btn btn-primary shadow rounded-circle p-2"
              >
                <IcoDel />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <label for="inputMessage" class="form-label">Contenu du mail</label>
          <textarea
            class="form-control"
            id="inputMessage"
            rows="3"
            v-model="form.contenu"
            :class="{
              'is-invalid': submitted && $v.form.message_faq.$error,
            }"
          ></textarea>
          <!-- <div
            v-if="submitted && !$v.form.message_faq.required"
            class="invalid-feedback"
          >
            Veuillez insérer votre message ici
          </div> -->
        </div>

        <div class="col-12 mb-2 mt-4">
          <button
            v-if="loading.send"
            class="btn btn-primary"
            type="button"
            disabled
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Envoi...
          </button>
          <button
            v-else
            type="submit"
            class="btn btn-primary"
            formnovalidate="formnovalidate"
          >
            Envoyer
            <!-- <IcoSend /> -->
          </button>
        </div>
      </form>
    </div>
    <div>
      <h2>Liste des abonnées</h2>
      <hr />
      <div>
        <table class="table table-hover table-responsive">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Email</th>
              <th scope="col">Date d'abonnement</th>
              <th scope="col" class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data" :key="item.id_news">
              <th scope="row">{{ item.id_news }}</th>
              <td>{{ item.mail_news }}</td>
              <td>
                {{
                  moment(item.date).calendar(null, {
                    sameWeek: "ddd",
                    sameElse: "DD MMMM YYYY",
                    sameDay: "[Aujourd'hui]",
                    nextDay: "[Demain]",
                    lastWeek: "dddd [dernier]",
                    nextWeek: "dddd",
                    lastDay: "[Hier]",
                  })
                }}
              </td>
              <td class="text-center">
                <div><IcoDelete /></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import IcoDelete from "vue-material-design-icons/Delete.vue";
import { getNewsLetter, sendMail } from "../../api/newsLetter";
import IcoAdd from "vue-material-design-icons/Plus.vue";
import IcoDel from "vue-material-design-icons/Close.vue";
import { error, success } from "../../utils/toast";

export default {
  name: "ArticleListe",
  components: {
    IcoDelete,
    IcoAdd,
    IcoDel,
  },
  data() {
    return {
      data: [],
      form: {
        object: "",
        contenu: "",
      },
      idDelete: 0,
      loading: {
        send: false,
        data: false,
        delete: false,
      },
      files: [{ name: "", file: null }],
    };
  },
  mounted() {
    getNewsLetter().then((result) => {
      this.data = result.data;
    });
  },
  methods: {
    addField() {
      this.files.push({ name: "", file: null });
    },
    removeField(index) {
      this.files.splice(index, 1);
    },
    changeFile(e, index) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event) => {
        const base64 = event.target.result;
        this.files[index].file = base64;
      };
    },
    sendMail() {
      this.loading.send = true;
      sendMail(this.form, this.files).then((result) => {
        this.loading.send = false;
        if (result.data.error) {
          error(result.data.error);
        } else {
          success("Email envoyé");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
