import axios from "axios";
import { BASE_URL } from "../configs";

const URL = BASE_URL + "/candidatNewsLetter";

export const createNewsLetter = (data) => {
  return axios.post(`${URL}`, data);
};

export const getNewsLetter = () => {
  return axios.get(`${URL}`);
};

export const sendMail = (form, files) => {
  form.file = [];
  files.forEach((e) => {
    if (e.file) {
      const [, type] = e.file.split(";")[0].split("/");
      const data = e.file.split("base64,")[1];
      const file = {
        filename: `${e.name}-file.${type}`,
        content: data,
        encoding: "base64",
      };
      form.file.push(file);
    }
  });
  //console.log(form);
  return axios.post(`${BASE_URL}/messageCandidatNewsLetter`, form);
};

export const deleteNewsLetter = (data) => {
  return axios.delete(`${BASE_URL}/candidatNewsLetter/${data}`);
};
